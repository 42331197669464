/* theme */
body {
  font-family: 'Josefin Sans', sans-serif !important;
  background-color: #fff;
  color: #505050;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Slab', serif;
}
a, a:hover {
  color: #505050 !important;
}
button {
  background-color: #505050 !important;
  border: none !important;
  font-size: 15px !important;
}
button:focus {
  outline: none !important;
  outline:0 !important;
  box-shadow:none !important;
  background-color: #000000 !important;
}
.menu-btns:focus, .menu-btns:hover, .menu-btns:active {
  background-color: #000000 !important;
}
button.navbar-toggler {
  background-color: transparent !important;
}
.footer-link {
  text-decoration: none !important;
}
.line-border {
  border-top: solid 1px #505050;
}
.header {
  background-color: #ffffff;
  box-shadow: 0px 3px 10px #ccc;
}
#header-wrapper {
  background-color: #ffffff;
  box-shadow: 0px 1px 3px #ccc;
}

.vertical-center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.bg-image {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 400px;
}
.bg-image-text-container {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}
.bg-image-text-bg {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem 2rem 1rem;
  outline: 2px solid rgba(255, 255, 255, 0.9);
  outline-offset: 5px;
}



@media screen and (min-width: 992px) {
  .parallax-card {
max-width:60%
  }
  #order-online {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  }
  #homepage-text-bg span{
    font-size: 4rem;
  }
  .bg-image-text-bg span {
    font-size: 4rem;
  }
}
@media screen and (max-width: 991px) {
  #order-online {
    width: fit-content;
    }
    #homepage-text-bg span{
      font-size: calc(1.375rem + 1.5vw)
    }
    .address-lane {
      width: 100%;
      display: block;
      padding-right: 0 !important;
      padding-left: 0 !important;
      padding-top: 0.2rem;
    }
    .bg-image-text-bg span {
      font-size: 3rem;
    }
}
@media screen and (min-width: 769px) {
  .description-icons {
    flex-basis: fit-content;
  }
}
@media screen and (max-width: 768px) {
  .description-icons {
    flex-basis: 33%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    
  }
  .description-icons h5{
    font-size: 1.2rem;
  }
}

.link-icon:hover {
  transform: scale(1.1);
}

.menu-item {
  max-width: 100%;
    height: auto;
    display: block;
}
#menu-banner {
  background-image: url("./assets/about-banner.webp");
}
#about-banner {
  background-image: url("./assets/menu-banner.webp");
}
#contact-banner {
  background-image: url("./assets/contact-banner.webp");
}
.card-title {
  font-size: 1.2rem;
}